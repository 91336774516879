
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { ObjectUtils } from '@/utils/objects'
import { localStore } from '@/utils/localstore'
import { constant } from '@/utils/constants'

@Options({
  components: {},
  directives: { maska },
})
export default class BaseFormMixin extends Vue {
  parentForm = {
    _id: '',
  }

  initForm = { _id: '' }

  testMode = false
  isCancel = false

  get appId() {
    return this.$route.params.app_id
  }

  get isNew() {
    return !this.parentForm?._id
  }

  async checkDraftValue() {
    if (Object.keys(this.initForm).length && ObjectUtils.isDifference(this.parentForm, this.initForm)) {
      return await this.notifyUnSaveForm()
    } else {
      return true
    }
  }

  async checkDraftValueOnCampaign() {
    if (Object.keys(this.initForm).length && ObjectUtils.isDifference(this.parentForm, this.initForm)) {
      return await this.notifyUnSaveForm()
    } else {
      return false
    }
  }

  async notifyUnSaveForm() {
    let draftFormUnsaved = this.$t('messages.draft_form_unsaved')
    if (this.isCancel) {
      draftFormUnsaved = this.$t('messages.do_you_revert_before_editing')
    }

    if (
      await new Promise((resolve) =>
        this.$q
          .dialog({
            title: this.$t('messages.confirm'),
            message: draftFormUnsaved,
            cancel: {
              flat: true,
              label: this.$t('messages.no'),
            },
            ok: {
              flat: true,
              label: this.$t('messages.yes'),
            },
            persistent: true,
          })
          .onOk(async () => {
            if (this.isCancel) {
              this.handleResetScenarios()
            }
            resolve(true)
          })
          .onCancel(async () => {
            resolve(false)
          })
      )
    ) {
      return true
    } else {
      return false
    }
  }

  onCancel() {
    this.checkDraftValue()
  }

  handleResetScenarios() {
    if (this.$route.name === 'initial_setting' || this.$route.name === 'push_setting') {
      let campaignId = ''
      if (this.$route.name === 'initial_setting') {
        campaignId = this.$route.params.initial_id
      } else {
        campaignId = this.$route.params.push_id
      }
      const key = constant.LOCAL_KEYS.SESSION_SCENARIOS + this.appId + '-' + campaignId
      localStore.removeStartsWith(key)
    }
  }

  handleBeforeUnload() {
    console.log('handleBeforeUnload', this.$route.name)
    if (this.$route.name === 'assets') {
      localStore.removeStartsWith(constant.LOCAL_KEYS.ASSETS_MESSAGES + this.appId)
      localStore.removeStartsWith(constant.LOCAL_KEYS.ASSETS_QAS + this.appId)
      localStore.removeStartsWith(constant.LOCAL_KEYS.ASSETS_GOALS + this.appId)
      localStore.removeStartsWith(constant.LOCAL_KEYS.ASSETS_FORMS + this.appId)
    }

    if (this.$route.name === 'builder') {
      localStore.removeStartsWith(constant.LOCAL_KEYS.AUDIENCES + this.appId)
      localStore.removeStartsWith(constant.LOCAL_KEYS.AUDIENCE_USERS + this.appId)
    }

    if (
      this.$route.name === 'campaigns' ||
      this.$route.name === 'popups' ||
      this.$route.name === 'stories' ||
      this.$route.name === 'story_setting'
    ) {
      localStore.removeStartsWith(constant.LOCAL_KEYS.CAMPAIGNS + this.appId)
      localStore.removeStartsWith(constant.LOCAL_KEYS.CAMPAIGN_TAGURL)
      localStore.removeStartsWith(constant.LOCAL_KEYS.POPUPS + this.appId)
    }

    if (this.$route.name === 'campaign_traffic_sources') {
      localStore.removeStartsWith(constant.LOCAL_KEYS.CAMPAIGNS + this.appId)
      localStore.removeStartsWith(constant.LOCAL_KEYS.TRAFFIC_SOURCES + this.appId)
    }
    if (
      this.$route.name === 'initials' ||
      this.$route.name === 'initial_setting' ||
      this.$route.name === 'stories' ||
      this.$route.name === 'story_setting'
    ) {
      localStore.removeStartsWith(constant.LOCAL_KEYS.INITIALS + this.appId)
      localStore.removeStartsWith(constant.LOCAL_KEYS.AUDIENCES)
      localStore.removeStartsWith(constant.LOCAL_KEYS.SCENARIO_TEMPLATES + this.appId)
      localStore.removeStartsWith(constant.LOCAL_KEYS.TARGET_SETTINGS + this.appId)
      localStore.removeStartsWith(constant.LOCAL_KEYS.SESSION_SCENARIOS + this.appId)
      localStore.removeStartsWith(constant.LOCAL_KEYS.TOTAL_USERS_REPORT_SCENARIO + this.appId)
    }
    if (this.$route.name === 'media_manager') {
      localStore.removeStartsWith(constant.LOCAL_KEYS.MEDIAS + this.appId)
    }
    if (
      this.$route.name === 'pushes' ||
      this.$route.name === 'push_setting' ||
      this.$route.name === 'stories' ||
      this.$route.name === 'story_setting'
    ) {
      localStore.removeStartsWith(constant.LOCAL_KEYS.PUSHES + this.appId)
      localStore.removeStartsWith(constant.LOCAL_KEYS.AUDIENCES)
      localStore.removeStartsWith(constant.LOCAL_KEYS.SCENARIO_TEMPLATES + this.appId)
      localStore.removeStartsWith(constant.LOCAL_KEYS.DELIVER_LOGS + this.appId)
      localStore.removeStartsWith(constant.LOCAL_KEYS.TARGET_SETTINGS + this.appId)
      localStore.removeStartsWith(constant.LOCAL_KEYS.SESSION_SCENARIOS + this.appId)
      localStore.removeStartsWith(constant.LOCAL_KEYS.TOTAL_USERS_REPORT_SCENARIO + this.appId)
    }
    if (this.$route.name === 'form_report') {
      localStore.removeStartsWith(constant.LOCAL_KEYS.ASSETS_FORMS + this.appId)
      localStore.removeStartsWith(constant.LOCAL_KEYS.ANSWER_REPORT + this.appId)
    }
    if (this.$route.name === 'q_a_report') {
      localStore.removeStartsWith(constant.LOCAL_KEYS.ASSETS_QAS + this.appId)
      localStore.removeStartsWith(constant.LOCAL_KEYS.AUDIENCE_USERS + this.appId)
      localStore.removeStartsWith(constant.LOCAL_KEYS.APP_USERS + this.appId)
      localStore.removeStartsWith(constant.LOCAL_KEYS.AUDIENCE_USER_DETAILS + this.appId)
    }
    if (this.$route.name === 'rich_menus') {
      localStore.removeStartsWith(constant.LOCAL_KEYS.RICH_MENUS + this.appId)
    }
    if (this.$route.name === 'scenario_templates') {
      localStore.removeStartsWith(constant.LOCAL_KEYS.SCENARIO_TEMPLATES + this.appId)
    }
    if (this.$route.name === 'target_settings') {
      localStore.removeStartsWith(constant.LOCAL_KEYS.TARGET_SETTINGS + this.appId)
      localStore.removeStartsWith(constant.LOCAL_KEYS.CAMPAIGN_TAGURL)
    }
    if (this.$route.name === 'traffic_sources') {
      localStore.removeStartsWith(constant.LOCAL_KEYS.TRAFFIC_SOURCES + this.appId)
    }
    if (this.$route.name === 'overview_details') {
      localStore.removeStartsWith(constant.LOCAL_KEYS.APP_OVER_VIEW_DETAIL + this.appId)
    }
    if (this.$route.name === 'overview') {
      localStore.removeStartsWith(constant.LOCAL_KEYS.APP_OVER_VIEW + this.appId)
    }
    if (
      this.$route.name === 'app_line_users' ||
      this.$route.name === 'stories' ||
      this.$route.name === 'story_setting'
    ) {
      localStore.removeStartsWith(constant.LOCAL_KEYS.APP_USERS + this.appId)
      localStore.removeStartsWith(constant.LOCAL_KEYS.PUSHES + this.appId)
      localStore.removeStartsWith(constant.LOCAL_KEYS.INITIALS + this.appId)
    }
    if (this.$route.name === 'app_line_user_profile') {
      localStore.removeStartsWith(constant.LOCAL_KEYS.TARGET_SETTINGS + this.appId)
      localStore.removeStartsWith(constant.LOCAL_KEYS.ACTIVITY_LOGS + this.appId)
      localStore.removeStartsWith(constant.LOCAL_KEYS.APP_USERS + this.appId)
    }
    if (this.$route.name === 'users') {
      localStore.removeStartsWith(constant.LOCAL_KEYS.USERS)
    }
    if (this.$route.name === 'home') {
      localStore.removeStartsWith(constant.LOCAL_KEYS.APPS)
      localStore.removeStartsWith(constant.LOCAL_KEYS.PERMISSION_USERS)
    }
    if (this.$route.name === 'app_information') {
      localStore.removeStartsWith(constant.LOCAL_KEYS.AUTO_RESPONSES)
    }
    if (this.$route.name === 'stories' || this.$route.name === 'story_setting') {
      localStore.removeStartsWith(constant.LOCAL_KEYS.STORIES)
    }
  }

  mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload)
  }

  beforeUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  }
}
